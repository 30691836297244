<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="set-ma-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
  <template v-if="centerList.length > 0">
    <div class="smd-title">选择需要配置的研究中心</div>
    <div class="smd-centers" v-loading="loading">
      <div class="smdc-item" v-for="(item, index) in centerList" :key="index" @click="handleActived(item)">
        <template v-if="item.txt_hospital_name.length<5">
          <el-button :class="item.t_hospital_project_id==activedBtn?'isActived':''">{{ item.txt_hospital_name }}</el-button>
        </template>
        <template v-else>
          <el-tooltip class="box-item" effect="dark" :content="item.txt_hospital_name" placement="top-start">
            <el-button :class="item.t_hospital_project_id==activedBtn?'isActived':''">{{ item.txt_hospital_name.slice(0, 5) + "..." }}</el-button>
          </el-tooltip>
        </template>
      </div>
    </div>
    <div class="smd-selectors">
      <div class="smds-select">
        <div class="row-center-start">
          <div class="smdss-title">选择研究者（可多选）：</div>
          <el-select v-model="current.researchers" filterable :filter-method="researcherFilter" multiple collapse-tags collapse-tags-tooltip @change="reChange" @focus="reBlur">
            <el-option v-for="(item, index) in options.researchers" :key="index" mul :label="item.txt_researcher_name" :value="item.t_researcher_id" v-show="item.isShow">
              <span style="float: left">{{ item.txt_researcher_name }}</span>
              <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px; "> {{ item.txt_researcher_code||"- -" }} </span>
            </el-option>
          </el-select>
        </div>
        <div class="hint" v-show="hint.reShow">请选择至少一个研究者</div>
      </div>
      <div class="smds-select">
        <div class="row-center-start">
          <div class="smdss-title">选择CRC（可多选）：</div>
          <el-select v-model="current.crcs" filterable :filter-method="crcFilter" multiple collapse-tags collapse-tags-tooltip @change="crcChange" @focus="crcBlur">
            <el-option v-for="(item, index) in options.crcs" :key="index" mul :label="item.txt_crc_name" :value="item.t_crc_id" v-show="item.isShow">
              <span style="float: left">{{ item.txt_crc_name }}</span>
              <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px; "> {{ item.txt_crc_code||"- -" }} </span>
            </el-option>
          </el-select>
        </div>
        <div class="hint" v-show="hint.crcShow">请选择至少一个CRC</div>
      </div>
      <div class="smds-select">
        <div class="row-center-start">
          <div class="smdss-title">选择CRA：</div>
          <el-select clearable v-model="current.cra" filterable>
            <el-option v-for="(item, index) in options.cras" :key="index" :label="item.txt_cra_name" :value="item.t_cra_id"></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="no-data column-center-center">暂无中心信息，请先进行【分配中心】</div>
  </template>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close">取消</el-button>
      <el-button type="primary" color="#8a88c3" @click="handleSubmit" v-preventReClick>保存</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 项目管理 - 人员配置
// "type": "2" //类型 1新增 2删除 3修改
import { onMounted, reactive, toRefs } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getCentersByProjectId, getCrasInPro, getAllResearchers, getAllCRCs, getCrcAndResInDis, saveCenterCRCAndPI } from "api/apis.js";
export default {
  props: {
    data: { type: Object }
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      title: "",
      loading: false,
      centerList: [],
      activedBtn: null,
      options: {
        researchers: [],
        researchersOld: [],
        crcs: [],
        crcsOld: [],
        cras: []
      },
      current: {
        researchers: [],
        crcs: [],
        cra: null
      },
      check: {},
      hint: {
        reShow: false,
        crcShow: false
      }
    })

    onMounted(() => {
      state.title = "【" + prop.data.txt_project_name.slice(0, 24) + "...】- 人员配置";
      getCenters();
    })

    // 研究者 自定义筛选
    const researcherFilter = e => {
      state.options.researchers = state.options.researchersOld.map(item => {
        return {
          ...item,
          isShow: (item.txt_researcher_name || "").includes(e) || (item.txt_researcher_code || "").includes(e)
        }
      })
    };

    // 研究者 选择器 离焦事件
    const reBlur = (e) => {
      if (!e.target.value) {
        state.options.researchers = state.options.researchersOld.map(item => {
          return {
            ...item,
            isShow: true
          }
        })
      }
    };

    // CRC 选择器 离焦事件
    const crcBlur = (e) => {
      if (!e.target.value) {
        state.options.crcs = state.options.crcsOld.map(item => {
          return {
            ...item,
            isShow: true
          }
        })
      }
    };

    // CRC 自定义筛选
    const crcFilter = e => {
      state.options.crcs = state.options.crcsOld.map(item => {
        return {
          ...item,
          isShow: (item.txt_crc_name || "").includes(e) || (item.txt_crc_code || "").includes(e)
        }
      })
    };

    // 保存配置
    const handleSubmit = () => {
      let result = checkChange();
      if (result) {
        // 有变化，格式化数据
        let researchers = state.check.researchers.map(t => {
          return {
            type: t.type,
            t_researcher_id: t.t_researcher_id,
            t_researcher_hospital_project_id: t.t_researcher_hospital_project_id || null
          }
        });
        let crcs = state.check.crcs.map(t => {
          return {
            type: t.type,
            t_crc_id: t.t_crc_id,
            t_crc_hospital_project_id: t.t_crc_hospital_project_id || null
          };
        });

        // CRA
        let hospitalCRAListDTO = [];

        // 原始选项 不为 null
        if ((state.check.cra || "") != "") {
          // 找到选项中 原始数据的位置
          let index = state.options.cras.findIndex(t => t.t_cra_id == state.check.cra),
            oldItem = state.options.cras[index];
          // 如果 原始数据 == 新数据
          if (state.check.cra == state.current.cra) {
            oldItem.type = "4";
            hospitalCRAListDTO.push(oldItem);
          } else {
            // 原始数据 != 新数据
            // 原数据类型改为 “2 删除”
            oldItem.type = "2";
            hospitalCRAListDTO.push(oldItem);

            // 新数据 不为 null
            if ((state.current.cra || "") != "") {
              // 找到 新数据 在 选项中的位置
              let newIndex = state.options.cras.findIndex(t => t.t_cra_id == state.current.cra),
                newItem = state.options.cras[newIndex];
              //   新数据 类型为 “1 新增”
              newItem.type = "1";
              hospitalCRAListDTO.push(newItem);
            }
          }
        } else {
          // 原始数据 == null  新数据 不为 null
          if ((state.current.cra || "") != "") {
            // 找到 新数据 在 选项中的位置
            let newIndex = state.options.cras.findIndex(t => t.t_cra_id == state.current.cra),
              newItem = state.options.cras[newIndex];
            //   新数据 类型为 “1 新增”
            newItem.type = "1";
            hospitalCRAListDTO.push(newItem);
          }
        }

        // 必填校验
        if (crcs.length == 0) {
          state.hint.crcShow = true;
        } else if (researchers.length == 0) {
          state.hint.reShow = true;
        } else {
          // 校验通过，调用接口保存
          state.hint.crcShow = false;
          state.hint.reShow = false;
          let param = {
            t_hospital_project_id: state.activedBtn,
            hospitalCRCListDTO: crcs,
            hospitalResearcherListDTO: researchers,
            hospitalCRAListDTO
          }
          state.loading = true;
          saveCenterCRCAndPI(param).then(response => {
            if (response.code == 200) {
              ElMessage.success("配置成功");
              getDisedData();
              getCRAs();
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.loading = false;
          })
        }
      } else {
        ctx.emit("close");
      }
    };

    // 中心选择事件
    const handleActived = item => {
      let result = checkChange();
      if (result) {
        showMessageBox('changeBtn', item);
      } else {
        state.current = {
          researchers: [],
          crcs: [],
          cra: null
        };
        state.check = {};
        state.activedBtn = item.t_hospital_project_id;
        getDisedData();
        getCRAs();
      }
    };

    // 显示 messageBox
    const showMessageBox = (type, item) => {
      ElMessageBox.confirm("当前配置尚未保存，请确认是否取消保存？", "提示", {
        confirmButtonText: "确认退出",
        cancelButtonText: "继续编辑"
      }).then(() => {
        if (type == "changeBtn") {
          state.activedBtn = item.t_hospital_project_id;
          getDisedData();
          getCRAs();
        } else {
          // 确认退出
          ctx.emit("close");
        }
      })
    };

    // 检测 当前中心 下 研究者 、 CRC 和 CRA 数据 是否有变化
    const checkChange = () => {
      let reChange = state.check.researchers ? state.check.researchers.filter(t => t.type != "4") : [],
        crcChange = state.check.crcs ? state.check.crcs.filter(t => t.type != "4") : [],
        craChange = state.check.cra != state.current.cra;
      if (reChange.length > 0 || crcChange.length > 0 || craChange) {
        return true;
      } else {
        return false;
      }
    };

    // 研究者 select 变化事件
    const reChange = event => {
      // 如果 改变后的 数组长度 大于 对照组
      if (event.length > state.check.researchers.length) {
        // 循环 当前选中值 数组，与 对照组 对比，找到不同值
        for (let i = 0; i < event.length; i++) {
          let index = state.check.researchers.findIndex(t => t.t_researcher_id == event[i]);
          // 当前值在 对照组 中不存在
          if (index == -1) {
            // 在选项中找到对应id的数据
            let optionIndex = state.options.researchers.findIndex(t => t.t_researcher_id == event[i]);
            // 深拷贝
            let tempObj = JSON.parse(JSON.stringify(state.options.researchers[optionIndex]));
            // 修改状态为新增
            tempObj.type = "1";
            state.check.researchers.push(tempObj);
          } else {
            // 当前值在对照组中存在
            // 考虑 原有 -> 删除 -> 又新增
            if (state.check.researchers[index].type == "2") {
              state.check.researchers[index].type = "4";
            }
          }
        }
      } else if (event.length < state.check.researchers.length) {
        // 当前被选中数组 长度 小于 对照组（双击取消选中的情况）
        for (let i = 0; i < state.check.researchers.length; i++) {
          let item = state.check.researchers[i],
            eIndex = event.findIndex(t => t == item.t_researcher_id);
          // 找到 对照组 中 与 选中 值不一样的
          if (eIndex == -1) {
            // 在 对照组中是新增的数据，直接删掉
            if (item.type == "1") {
              state.check.researchers.splice(i, 1);
              // i = i-1;
            } else {
              // 在 对照组 中 是原始数据
              // 修改状态 为 删除 type = 2;
              state.check.researchers[i].type = "2";
            }
          } else {
            // 当前值在对照组中存在
            // 考虑 原有 -> 删除 -> 又新增
            if (state.check.researchers[i].type == "2") {
              state.check.researchers[i].type = "4";
            }
          }
        }
      } else {
        // 当 被选中数据 与 对照组 长度一致时，
        let end = event[event.length - 1];
        let tempIndex = state.check.researchers.findIndex(t => t.t_researcher_id == end);
        if (tempIndex == -1) {
          let selectIndex = state.options.researchers.findIndex(t => t.t_researcher_id == end);
          state.options.researchers[selectIndex].type = "1";
          state.check.researchers.push(state.options.researchers[selectIndex]);
        } else {
          state.check.researchers[tempIndex].type = "4";
        }
      }
    };

    // CRC select 变化事件
    const crcChange = event => {
      // 如果 改变后的 数组长度 大于 对照组
      if (event.length > state.check.crcs.length) {
        // 循环 当前选中值 数组，与 对照组 对比，找到不同值
        for (let i = 0; i < event.length; i++) {
          let index = state.check.crcs.findIndex(t => t.t_crc_id == event[i]);
          // 当前值在 对照组 中不存在
          if (index == -1) {
            // 在选项中找到对应id的数据
            let optionIndex = state.options.crcs.findIndex(t => t.t_crc_id == event[i]);
            // 深拷贝
            let tempObj = JSON.parse(JSON.stringify(state.options.crcs[optionIndex]));
            // 修改状态为新增
            tempObj.type = "1";
            state.check.crcs.push(tempObj);
          } else {
            // 当前值在对照组中存在
            // 考虑 原有 -> 删除 -> 又新增
            if (state.check.crcs[index].type == "2") {
              state.check.crcs[index].type = "4";
            }
          }
        }
      } else if (event.length < state.check.crcs.length) {
        if (event.length != 0) {
          let end = event[event.length - 1];
          let tempIndex = state.check.crcs.findIndex(t => t.t_crc_id == end);
          if (tempIndex == -1) {
            let selectIndex = state.options.crcs.findIndex(t => t.t_crc_id == end);
            state.options.crcs[selectIndex].type = "1";
            state.check.crcs.push(state.options.crcs[selectIndex]);
          }
        }

        // 当前被选中数组 长度 小于 对照组（双击取消选中的情况）
        for (let i = 0; i < state.check.crcs.length; i++) {
          let item = state.check.crcs[i],
            eIndex = event.findIndex(t => t == item.t_crc_id);
          // 找到 对照组 中 与 选中 值不一样的
          if (eIndex == -1) {
            // 在 对照组中是新增的数据，直接删掉
            if (item.type == "1") {
              state.check.crcs.splice(i, 1);
            } else {
              // 在 对照组 中 是原始数据
              // 修改状态 为 删除 type = 2;
              state.check.crcs[i].type = "2";
            }
          } else {
            // 当前值在对照组中存在
            // 考虑 原有 -> 删除 -> 又新增
            if (state.check.crcs[i].type == "2") {
              state.check.crcs[i].type = "4";
            }
          }
        }
      } else {
        // 当 被选中数据 与 对照组 长度一致时，
        let end = event[event.length - 1];
        let tempIndex = state.check.crcs.findIndex(t => t.t_crc_id == end);
        if (tempIndex == -1) {
          let selectIndex = state.options.crcs.findIndex(t => t.t_crc_id == end);
          state.options.crcs[selectIndex].type = "1";
          state.check.crcs.push(state.options.crcs[selectIndex]);
        } else {
          state.check.crcs[tempIndex].type = "4";
        }
      }
    };

    // 获取已分配中心的CRC和研究者
    const getDisedData = () => {
      getCrcAndResInDis({ t_hospital_project_id: state.activedBtn }).then(response => {
        if (response.code == 200) {
          let data = response.data[0];
          if (data.crcList.length > 0) {
            // select 赋值
            state.current.crcs = data.crcList.map(t => t.t_crc_id);
            // 对照组赋值
            state.check.crcs = data.crcList.map(t => {
              return {
                t_crc_hospital_project_id: t.t_crc_hospital_project_id,
                t_hospital_project_id: t.t_hospital_project_id,
                t_crc_id: t.t_crc_id,
                type: "4"
              }
            })
          } else {
            state.current.crcs = [];
            state.check.crcs = [];
          }

          if (data.researcherList.length > 0) {
            // select 赋值
            state.current.researchers = data.researcherList.map(t => t.t_researcher_id);
            // 对照组赋值
            state.check.researchers = data.researcherList.map(t => {
              return {
                t_researcher_hospital_project_id: t.t_researcher_hospital_project_id,
                t_hospital_project_id: t.t_hospital_project_id,
                t_researcher_id: t.t_researcher_id,
                type: "4"
              }
            })
          } else {
            state.current.researchers = [];
            state.check.researchers = [];
          }
        } else {
          state.current = {
            researchers: [],
            crcs: []
          };
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取所有研究者
    const getResearcherList = () => {
      getAllResearchers().then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.options.researchersOld = response.data.map(t => {
              return {
                t_researcher_id: t.t_researcher_id,
                txt_researcher_code: t.txt_researcher_code,
                txt_researcher_name: t.txt_researcher_name,
                isShow: true
              }
            })
          } else {
            state.options.researchersOld = [];
          }
          state.options.researchers = JSON.parse(JSON.stringify(state.options.researchersOld));
        } else {
          ElMessage.error(response.msg);
          state.options.researchers = [];
          state.options.researchersOld = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getCRCs();
      })
    };

    // 获取所有CRC
    const getCRCs = () => {
      getAllCRCs().then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.options.crcsOld = response.data.map(t => {
              return {
                t_crc_id: t.t_crc_id,
                txt_crc_code: t.txt_crc_code,
                txt_crc_name: t.txt_crc_name,
                isShow: true
              }
            })
          } else {
            state.options.crcsOld = [];
          }
          state.options.crcs = JSON.parse(JSON.stringify(state.options.crcsOld));
        } else {
          ElMessage.error(response.msg);
          state.options.crcs = [];
          state.options.crcsOld = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getDisedData();
      })
    };

    // 获取项目下所有的中心
    const getCenters = () => {
      state.loading = true;
      getCentersByProjectId({ t_project_id: prop.data.t_project_id }).then(response => {
        if (response.code == 200) {
          state.centerList = response.data || [];
          if (state.centerList.length > 0) {
            state.activedBtn = state.centerList[0].t_hospital_project_id;
            getResearcherList();
            getCRAs();
          }
        } else {
          state.centerList = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 获取 项目下 所有CRA
    const getCRAs = () => {
      getCrasInPro({ t_hospital_project_id: state.activedBtn }).then(response => {
        if (response.code == 200) {
          // 选项赋值
          state.options.cras = response.data || [];
          let index = state.options.cras.findIndex(t => t.is_choose == "1");
          // "is_choose": "1" 是否已选中 0 未选中 1已选中
          if (index != -1) {
            // 初始值赋值
            state.current.cra = state.options.cras[index].t_cra_id;
            // 被选中 对象 初始 赋值
            state.check.cra = state.options.cras[index].t_cra_id;
          } else {
            state.current.cra = null;
            state.check.cra = null;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const close = () => {
      let result = checkChange();
      if (result) {
        showMessageBox('close');
      } else {
        ctx.emit("close");
      }
    };

    return {
      ...toRefs(state),
      close,
      getCenters,
      handleActived,
      getResearcherList,
      getCRCs,
      getDisedData,
      reChange,
      crcChange,
      checkChange,
      showMessageBox,
      handleSubmit,
      getCRAs,
      researcherFilter,
      crcFilter,
      reBlur,
      crcBlur,
    }
  }
}
</script>

<style lang="scss">
.set-ma-dialog {
  width: 700px;

  .dialog-footer {
    .el-button--primary {
      color: #ffffff;
    }
  }

  .no-data {
    height: 100%;
  }

  .smd-title {
    font-size: 16px;
  }

  .smd-centers {
    margin-top: 10px;
    padding: 10px 10px;

    .smdc-item {
      margin-right: 10px;
      display: inline-block;
      margin-bottom: 10px;
      width: calc((100% - 50px) / 6);

      .el-button {
        width: 100%;
        border-radius: 14px;
        border-color: #8a88c3;
        color: #8a88c3;

        &.isActived {
          background-color: #8a88c3;
          color: #ffffff;
          font-weight: bold;
        }
      }

      &:nth-child(n+6) {
        margin-right: 0;
      }
    }
  }

  .smd-selectors {
    margin-top: 24px;
    border-radius: 14px;
    padding: 16px 16px;
    border: 1px dashed #efecff;

    .smds-select {
      width: 100%;
      margin-bottom: 26px;

      .smdss-title {
        width: 28%;
      }

      .hint {
        margin-top: 6px;
        margin-left: 28%;
        color: rgb(199, 9, 9);
      }

      .el-select {
        width: 70%;
      }
    }
  }
}
</style>
