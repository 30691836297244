<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="interview-ma-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column type="index" width="80" label="序号" align="center" />
    <el-table-column prop="txt_interview_name" label="访视名称" align="center">
      <template #default="scope">
        <el-input v-model="scope.row.txt_interview_name" @blur="handleBlur(scope)" @input="()=>{scope.row.txt_interview_name=scope.row.txt_interview_name.replace(/(^\s*)|(\s*$)/g,'')}" placeholder="请输入访视名称" />
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option" width="120">
      <template #default="scope">
        <div class="table-icon new-color" v-if="scope.$index==table.data.length-1" @click="handleAdd(scope.row)">
          <el-icon>
            <Plus />
          </el-icon>
        </div>
        <div class="table-icon new-color" :class="table.data.length==1?'disabled':''" @click="handleMinus(scope)">
          <el-icon>
            <Minus />
          </el-icon>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="handleSubmit('cancel')">取消</el-button>
      <el-button type="primary" @click="handleSubmit('submit')">保存</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 项目管理 - 访视管理 - comp
// 访视管理：type:  1-新增  2-删除  3-修改
import { Plus, Minus } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { onMounted, reactive, toRefs, computed } from 'vue';
import { getInterviewsInPro, saveInterviewDis } from "api/apis.js";
export default {
  props: {
    data: { type: Object }
  },
  components: {
    Plus,
    Minus
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      title: computed(() => {
        if (prop.data.txt_project_name.length > 24) {
          return "【" + prop.data.txt_project_name.slice(0, 24) + "....】- 访视管理";
        } else {
          return "【" + prop.data.txt_project_name + "】- 访视管理";
        }
      }),
      table: {
        // 初始状态，给一个初始空输入框type=null，原有的数据添加type=4
        // [{ t_interview_id: 1, txt_interview_name: "a", type: 4 }, { t_interview_id: null, txt_interview_name: "", type: null }]
        data: [],
        loading: false,
        oldData: [],
        delData: []
      },
    })

    onMounted(() => {
      state.table.delData = [];

      getInterviews();
    })

    // 访视名称 - 输入框离焦事件
    const handleBlur = scope => {
      // 如果是初始数据 type=="4"|| scope.row.type == "3" 的情况（首次修改和二改）
      if (scope.row.type == "4" || scope.row.type == "3") {
        // 从存储 初始数据 的对象中找到 与刚被操作id 一致的数据
        let index = state.table.oldData.findIndex(t => t.t_interview_id == scope.row.t_interview_id);
        // 对比两个数据名称是否有变化
        if (state.table.oldData[index].txt_interview_name != scope.row.txt_interview_name) {
          // 有变化，修改 type="3"
          scope.row.type = "3";
        } else {
          // 再次二改 a -> b -> a 情况
          scope.row.type = "4";
        }
        // 如果是新增的一行 type==null || type == "1" 的情况
      } else if (scope.row.type == null || scope.row.type == "1") {
        if ((scope.row.txt_interview_name || "") != "") {
          // 修改 type="1" null -> a 情况
          scope.row.type = "1";
        } else {
          // null -> a -> null 情况
          scope.row.type = null;
        }
      }
    };

    // 获取项目下的所有访视
    const getInterviews = () => {
      state.table.loading = true;
      getInterviewsInPro({ t_project_id: prop.data.t_project_id }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            // 数据赋值
            state.table.data = response.data.map(t => {
              return {
                t_interview_id: t.t_interview_id,
                txt_interview_name: t.txt_interview_name,
                type: "4"
              }
            });
            // 深拷贝
            state.table.oldData = JSON.parse(JSON.stringify(state.table.data));
          } else {
            // 添加一行新数据
            state.table.data.push({ t_interview_id: null, txt_interview_name: "", type: null });
            // 重置 存储用的 对象
            state.table.oldData = [];
          }
        } else {
          ElMessage.error(response.msg);
          // 添加一行新数据
          state.table.data.push({ t_interview_id: null, txt_interview_name: "", type: null });
          // 重置 存储用的 对象
          state.table.oldData = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    }

    // 保存/取消
    const handleSubmit = btnType => {
      // 默认新增的数据 type 为 null 或 4 （初始数据）
      let updateIndex = state.table.data.findIndex(t => t.type != null && t.type != "4");
      // 有新增 / 修改的数据  或 有删除的数据
      if (updateIndex != -1 || state.table.delData.length > 0) {
        // 判断指令类别
        // 取消
        if (btnType == "cancel") {
          ElMessageBox.confirm("当前修改尚未保存，请确认是否取消保存？", "提示", {
            confirmButtonText: "确认退出",
            cancelButtonText: "继续编辑"
          }).then(() => {
            // 确认退出
            ctx.emit("close");
          })
          // 确认 
        } else {
          // 格式化数据，调接口保存
          let param = [];
          // 去掉 不是 初始数据 也不是 （新增行 但没值 的）
          let updateData = state.table.data.filter(t => t.type != null && t.type != "4");
          param = param.concat(updateData).concat(state.table.delData);
          state.table.loading = true;
          saveInterviewDis({ interviewList: param, t_project_id: prop.data.t_project_id }).then(response => {
            if (response.code == 200) {
              ElMessage.success("操作成功");
              ctx.emit("close");
            } else {
              ElMessage.error("操作失败：" + response.msg);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.table.loading = false;
          })
        }
      } else {
        // 数据无变化，直接退出
        ctx.emit("close");
      }
    };

    // 删除行
    const handleMinus = scope => {
      let index = scope.$index;
      // 判断是否是 已存在的访视，
      if (state.table.data[index].t_interview_id != null) {
        // 是，修改类型为 “删除”
        state.table.data[index].type = "2";
        // 同时把数据赋值给 “已删除的”，为调用保存接口做准备
        state.table.delData.push(state.table.data[index]);
      }
      // 根据索引 删除 对应数据
      state.table.data.splice(index, 1);
    };

    // 新增行
    const handleAdd = () => {
      state.table.data.push({ t_interview_id: null, txt_interview_name: "", type: null });
    };

    const close = () => {
      handleSubmit("cancel");
    };

    return {
      ...toRefs(state),
      close,
      handleAdd,
      handleMinus,
      handleSubmit,
      getInterviews,
      handleBlur
    }
  }
}
</script>

<style lang="scss">
.interview-ma-dialog {
  width: 650px;
  height: 50% !important;

  .table-icon {
    &.new-color {
      font-size: 22px !important;
      color: #8a88c3 !important;
      font-weight: bold !important;
    }
  }

  .dialog-footer {
    .el-button {
      border-radius: 14px;

      &:last-child {
        background-color: #8a88c3;
        border-color: #8a88c3;
      }
    }
  }
}
</style>
