<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="distribution-ma-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
  <el-transfer v-loading="loading" v-model="transferValue" :props="{
      key: 't_hospital_id',
      label: 'label',
    }" filterable :titles="['待选', '已选']" :data="trasferData" @change="handleChange">
  </el-transfer>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="handleSubmit('cancel')">取消</el-button>
      <el-button type="primary" color="#8a88c3" :disabled="oldData.length==0" @click="handleSubmit('submit')">保存</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 项目管理 - 分配中心
// "is_choose": 1 //是否 被选中 1已选择 0未选择
// type:  1-新增  2-删除  3-修改
import { ElMessage, ElMessageBox } from 'element-plus';
import { onMounted, reactive, toRefs, computed } from 'vue';
import { getCenterToDis, saveCenterForPro } from "api/apis.js";
export default {
  props: {
    data: { type: Object }
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      title: computed(() => {
        if (prop.data.txt_project_name.length > 24) {
          return "【" + prop.data.txt_project_name.slice(0, 24) + "....】- 分配中心";
        } else {
          return "【" + prop.data.txt_project_name + "】- 分配中心";
        }
      }),
      trasferData: [],
      transferValue: [],
      oldData: [],
      checkData: [],
      loading: false
    })

    onMounted(() => {
      state.trasferData = [];
      state.transferValue = [];
      state.oldData = [];
      state.checkData = [];
      getData();
    })

    // 提交
    const handleSubmit = type => {
      // 取消按钮
      if (type == "cancel") {
        // 原始数组有数据，弹窗提示
        if (state.oldData.length > 0) {
          if (state.oldData.findIndex(t => t.type != "4") != -1) {
            ElMessageBox.confirm("当前修改尚未保存，请确认是否取消保存？", "提示", {
              confirmButtonText: "确认退出",
              cancelButtonText: "继续编辑"
            }).then(() => {
              // 确认退出
              ctx.emit("close");
            })
          } else {
            // 确认退出
            ctx.emit("close");
          }
        } else {
          // 原始数组无数据，直接退出
          ctx.emit("close");
        }
      } else {
        // 原始数组有数据，走提交接口
        if (state.oldData.length > 0) {
          if (state.oldData.findIndex(t => t.type != "4") != -1) {
            state.loading = true;
            saveCenterForPro({ hospitalList: state.oldData, t_project_id: prop.data.t_project_id }).then(response => {
              if (response.code == 200) {
                ElMessage.success("操作成功");
                ctx.emit("close");
              } else {
                ElMessage.error(response.msg);
              }
            }).catch(e => {
              console.error(e);
            }).finally(() => {
              state.loading = false;
            })
          } else {
            ctx.emit("close");
          }
        }
      }
    };

    // 获取穿梭框 可展示的数据
    const getData = () => {
      state.loading = true;
      getCenterToDis({ t_project_id: prop.data.t_project_id }).then(response => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            let item = response.data[i];
            // 待选择列表 赋值
            state.trasferData.push({
              t_hospital_id: item.t_hospital_id,
              txt_hospital_name: item.txt_hospital_name,
              t_hospital_project_id: item.t_hospital_project_id,
              label: item.txt_hospital_name + "(" + item.txt_hospital_code + ")",
              type: "4"
            })
            if (item.is_choose == 1) {
              // 已选中列表赋值，给一个初始状态 type=4
              state.checkData.push({
                t_hospital_id: item.t_hospital_id,
                txt_hospital_name: item.txt_hospital_name,
                t_hospital_project_id: item.t_hospital_project_id,
                label: item.txt_hospital_name + "(" + item.txt_hospital_code + ")",
                type: "4"
              })
              state.transferValue.push(item.t_hospital_id);
            }
          }
          state.oldData = JSON.parse(JSON.stringify(state.checkData));
        } else {
          state.trasferData = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 穿梭框 值修改 事件
    const handleChange = (value, direction, moveKey) => {
      // 循环被移动的数据
      for (let i = 0; i < moveKey.length; i++) {
        // 向右移动，新增
        if (direction == "right") {
          // 被选中的 对照数组 长度大于0
          if (state.checkData.length > 0) {
            // 在 对照数组 中寻找是否存在 当前被操作的数据
            let checkIndex = state.checkData.findIndex(t => t.t_hospital_id == moveKey[i]);

            // 对照数组中存在该条数据
            if (checkIndex != -1) {
              // 在原始数组中插入该数据，状态为 4  
              // 情况可能是：a -> b -> a
              state.checkData[checkIndex].type = "4";
              // 在 穿梭框 展示数据中找到该条数据
              let newIndex = state.trasferData.findIndex(t => t.t_hospital_id == moveKey[i]);
              state.oldData[newIndex].type = "4";
            } else {
              // 对照数组中没有 该数据 
              // 为新数据
              // 在 穿梭框 展示数据中找到该条数据
              let newIndex = state.trasferData.findIndex(t => t.t_hospital_id == moveKey[i]);
              // 修改状态 type=1 新增
              state.trasferData[newIndex].type = "1";
              state.oldData.push(state.trasferData[newIndex]);
            }
          } else {
            // 对照组中无数据，直接插入
            let newIndex = state.trasferData.findIndex(t => t.t_hospital_id == moveKey[i]);
            // 修改状态 type=1 新增
            state.trasferData[newIndex].type = "1";
            state.oldData.push(state.trasferData[newIndex]);
          }
        } else {
          // 向左移动 删除
          let oldIndex = state.oldData.findIndex(t => t.t_hospital_id == moveKey[i]);
          // 被选中的 对照数组 长度大于0
          if (state.checkData.length > 0) {
            // 在 对照数组 中寻找是否存在 当前被操作的数据
            let checkIndex = state.checkData.findIndex(t => t.t_hospital_id == moveKey[i]);
            // 对照组 中存在该数据
            // 不存在的情况 无需考虑，可能是 新增->删除

            if (checkIndex != -1) {
              // 在原始数据中找到 该条数据 修改状态 type=2
              state.oldData[oldIndex].type = "2";
            } else {
              state.oldData.splice(oldIndex, 1);
            }
          } else {
            state.oldData.splice(oldIndex, 1);
          }
        }
      }
    };

    const close = () => {
      handleSubmit("cancel");
    };

    return {
      ...toRefs(state),
      close,
      handleChange,
      getData,
      handleSubmit
    }
  }
}
</script>

<style lang="scss">
.distribution-ma-dialog {
  width: 800px;
  height: 60% !important;

  .dialog-footer {
    .el-button--primary {
      color: #ffffff;
    }
  }

  .el-dialog__body {
    .el-transfer {
      height: 100%;

      .el-transfer-panel {
        height: 100%;
        width: calc((100% - 165px) / 2);

        .el-transfer-panel__header {
          background-color: #d2d2f4;
        }

        .el-transfer-panel__body {
          height: calc(100% - 46px);
        }

        .el-transfer-panel__item:hover {
          color: #9b99ca;
        }

        .el-checkbox__input {
          &.is-checked {
            .el-checkbox__inner {
              border-color: #8a88c3;
              background-color: #8a88c3;
            }
          }

          &.is-indeterminate {
            .el-checkbox__inner {
              border-color: #8a88c3;
              background-color: #8a88c3;
            }
          }

          &.is-checked+ {
            .el-checkbox__label {
              color: #8a88c3;
            }
          }

          .el-checkbox__inner {
            &:hover {
              border-color: #8a88c3;
            }
          }
        }
      }

      .el-transfer__buttons {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .el-button {
          background-color: #8a88c3;
          border-color: #9b9b9b;
          padding: 8px 8px;
          border-radius: 18px;

          &.el-transfer__button {
            &:last-child {
              margin: 10px 0 0 0;
            }
          }

          &.is-disabled {
            border-color: #d2d2f4;
            background-color: #d2d2f4;
          }
        }
      }
    }
  }
}
</style>
